:root {
  /* Primary */
  --color--primary-calm-green: #768970;
  --color--primary-dark-green: #364237;
  --color--primary-cold-green: #1e1c20;
  --color--primary-bright-green: #00db7d;

  --color--primary-blue: #3772ff;
  --color--primary-purple: #9757d7;
  --color--primary-orange: #ff6838;
  --color--primary-green: #58bd7d;
  --color--primary-red: #e24545;

  /* Secondary */
  --color--secondary-blue: #dfe9ff;
  --color--secondary-yellow: #ffd166;
  --color--secondary-purple: #dbf;
  --color--secondary-green: #b9f5cf;
  --color--neutral-1: #141416;
  --color--neutral-2: #23262f;
  --color--neutral-3: #353945;
  --color--neutral-4: #777e90;
  --color--neutral-5: #b1b5c3;
  --color--neutral-6: #e6e8ec;
  --color--neutral-7: #f4f5f6;
  --color--neutral-8: #fcfcfd;
  --color--neutral-9: #fff;

  /* Accent */
  --color--primary-blue-accent: #2955c0;

  --color--error: #ef4444;

  --toastify-color-info: var(--color--primary-blue);
  --toastify-color-success: var(--color--primary-green);
  --toastify-color-warning: var(--color--secondary-yellow);
  --toastify-color-error: var(--color--error);
}
