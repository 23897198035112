@font-face {
  font-family: PPNeueMontreal;
  src: local(PPNeueMontreal-Medium),
    url(./fonts/PPNeueMontreal/PPNeueMontreal-Medium.otf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: PPNeueMontreal;
  src: local(PPNeueMontreal-Book),
    url(./fonts/PPNeueMontreal/PPNeueMontreal-Book.otf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
  font-family: PPNeueMontreal;
  src: local(PPNeueMontreal-Thin),
    url(./fonts/PPNeueMontreal/PPNeueMontreal-Thin.otf) format('truetype');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

.font-Neue {
  font-family: PPNeueMontreal;
}
