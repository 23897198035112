@import 'tailwind.css';
@import 'colors.css';
@import 'fonts.css';
/*@import "datepicker.css";*/

body {
  font-family: PPNeueMontreal;
}

.showArrows::-webkit-inner-spin-button,
.showArrows::-webkit-outer-spin-button {
  opacity: 1 !important;
  -webkit-appearance: inner-spin-button !important;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
}
