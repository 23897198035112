:root {
  --rdp-cell-size: 48px;
  --rdp-accent-color: var(--color--neutral-6);
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.rdp-caption_label {
  font-family: PPNeueMontreal;
  font-size: 16px;
}

.rdp-multiple_months .rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start:after,
.rdp-multiple_months .rdp-button.rdp-day.rdp-day_selected.rdp-day_range_end:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color--neutral-2);
  border-radius: 50%;
}

.rdp-multiple_months .rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start,
.rdp-multiple_months .rdp-button.rdp-day.rdp-day_selected.rdp-day_range_end {
  /*border-color: var(--color--neutral-2);*/
}

.rdp-multiple_months .rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start > span,
.rdp-multiple_months .rdp-button.rdp-day.rdp-day_selected.rdp-day_range_end > span {
  z-index: 1;
}

.rdp-row .rdp-cell:last-child .rdp-day_selected:not([disabled]) {
  border-radius: 0 8px 8px 0;
}

button.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_middle {
  color: var(--color--neutral-2);
}
